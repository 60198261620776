import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from 'shared/utils/withStyles';
import styles from './tag.styl';
var Tag = /** @class */ (function (_super) {
    __extends(Tag, _super);
    function Tag() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (event) {
            event.stopPropagation();
            event.preventDefault();
            var _a = _this.props, onClick = _a.onClick, text = _a.text;
            onClick(text);
        };
        return _this;
    }
    Tag.prototype.render = function () {
        var _a;
        var _b = this.props, text = _b.text, className = _b.className, roundedClassName = _b.roundedClassName, rounded = _b.rounded, onFocus = _b.onFocus, onBlur = _b.onBlur;
        var classNames = classnames(className, styles.tag, (_a = {},
            _a[styles.rounded] = rounded,
            _a[roundedClassName] = rounded && roundedClassName,
            _a));
        return (React.createElement("a", { href: "#", className: classNames, onClick: this.handleClick, onFocus: onFocus, onBlur: onBlur },
            React.createElement("span", { className: styles.inner }, text)));
    };
    Tag.propTypes = {
        text: PropTypes.string.isRequired,
        className: PropTypes.string,
        roundedClassName: PropTypes.string,
        onFocus: PropTypes.func,
        onClick: PropTypes.func,
        onBlur: PropTypes.func,
        rounded: PropTypes.bool,
    };
    Tag.defaultProps = {
        onFocus: _.noop,
        onClick: _.noop,
        onBlur: _.noop,
        rounded: false,
    };
    Tag = __decorate([
        withStyles(styles)
    ], Tag);
    return Tag;
}(React.Component));
export default Tag;
